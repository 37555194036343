<template>
  <getecma-form v-if="group" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="group.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Grupo A" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Unidade de Medida de Coletas*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            v-bind="$attrs"
            name="unidade de medida"
            rules="required"
            placeholder="Selecione as unidade de medida"
            :value="capacitySelected.name"
            :options="capacities"
            @on-change="onCapacityChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Clientes*</getecma-header>
      </div>
      <div>
        <el-transfer
          v-model="selecteds"
          class="custom-transfer"
          filterable
          :filter-method="filterMethod"
          :titles="['Disponíveis', 'Selecionados']"
          :button-texts="['Remover', 'Adicionar']"
          filter-placeholder="Filtrar por cliente"
          :data="customers">
        </el-transfer>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCapacities, getCapacityById } from '@/modules/capacity/capacity.service';
import { fetchCustomers } from '@/modules/customer/customer.service';
import { fetchCustomersByGroup } from '@/modules/group/group.service';

export default {
  name: 'GetecmaGroupCreateInformation',
  components: {
  },
  inject: ['groupEditVm'],
  data() {
    return {
      group: this.groupEditVm.group,
      performer: getters.getUser(),
      capacities: [],
      capacitySelected: null,
      customers: [],
      selecteds: [],
      filterMethod(query, item) {
        return item.label && item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
    };
  },
  async mounted() {
    await this.fetchCapacities();
    await this.fetchCapacity();
    await this.fetchCustomers();
    await this.fetchCustomersByGroup();
  },
  methods: {
    goHistoryBack,
    async fetchCapacity() {
      getCapacityById(this.group.capacity_id)
        .then(data => {
          this.capacitySelected = data;
        })
        .catch(() => toastError('Erro ao obter unidade de medida por ID'));
    },
    async fetchCapacities() {
      try {
        const params = { limit: 'all', page: 1, search: '', type: 0 };
        const response = await fetchCapacities(params);
        this.capacities = response.rows.map(capacity => ({
          key: capacity.id,
          value: `${capacity.name} - ${capacity.liters} litros`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as cidades:', error);
      }
    },
    async fetchCustomers() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCustomers(params);
        this.customers = response.rows.map(customer => ({
          key: customer.id,
          label: (customer.contract_status) ? `(${customer.item_id}) ${customer.name}` : `(Inativo) (${customer.item_id}) ${customer.name}`,
        }));
      } catch (error) {
        console.error('Erro ao buscar os clientes:', error);
      }
    },
    fetchCustomersByGroup() {
      fetchCustomersByGroup(this.group.id)
        .then(response => {
          this.selecteds = response.rows.map(customer => customer.id);
        })
        .catch(() => toastError('Erro ao obter clientes por grupo'));
    },
    save() {
      if (this.selecteds.length === 0) {
        toastError('Você precisa selecionar pelo menos um cliente');
      } else {
        this.group.customers = this.selecteds;
        this.$emit('save');
      }
    },
    onCapacityChange(capacitySelected) {
      this.group.capacity_id = capacitySelected.key;
    },
  },
};
</script>
